export default [
  {
    title: 'Statistiques',
    route: 'home',
    icon: 'ActivityIcon',
  },
  
  
  {
    title: 'Gains',
    route: 'orders',
    icon: 'DollarSignIcon',
  },

  {
    title: 'Informations personnelles',
    route: 'profile',
    icon: 'UserIcon',
  },
  {
    title: 'Catalogue',
    route: 'glasses',
    icon: 'BookOpenIcon',
  },
  {
    title: 'Codes promo',
    route: 'promotions',
    icon: 'PercentIcon',
  }
  

]
